@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;500;600&display=swap');

body,
body .p-component {
  font-family: 'Quicksand', sans-serif !important;
}

.p-element td {
  text-overflow: ellipsis;
}

.p-menuitem-icon {
  margin-right: 8px;
}

.p-0-card .p-card-body {
  padding: 0 !important;
}

.p-tabmenu-nav-content {
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
}

.table-card .p-card-body, .table-card .p-card-content {
  padding: 0 0 !important;
}

.table-card .p-card-content .p-datatable-header {
  border-radius: 4px 4px 0 0;
}

.table-card .p-card-content .p-datatable .p-paginator-bottom {
  border-radius: 0 0 4px 4px !important;
  border-width: 0 !important;
}

.table-card .p-card-content .p-datatable-loading-overlay {
  border-radius: 4px !important
}
